<script>
import { mapGetters, mapMutations } from 'vuex'
import ModalColheita from '@/pages/execucao/ModalColheita'
import PermissionMixin from '@/components/Permission/PermissionMixin'
import { logEvent, events } from '@/services/analytics'
import { convertToTargetUnit } from '@/components/Form/converter'

export default {
  mixins: [PermissionMixin],

  data() {
    return {
      openHarvestForm: false,
    }
  },

  props: {
    semeadura: {
      type: Object,
      default: null,
    },
  },

  components: {
    ModalColheita,
  },

  computed: {
    ...mapGetters('semeaduras', ['fazenda']),
    ...mapGetters('farms', ['currentFarmId', 'enabledFarm']),
    ...mapGetters('seasonsCrops', ['currentSeasonCrop']),

    showMenu() {
      return (
        this.hasPermission(this.$p.planting.edit) ||
        this.hasPermission(this.$p.planting.delete) ||
        this.hasPermission(this.$p.harvest.create)
      )
    },

    harvestLost() {
      return this.semeadura?.colheita?.lost
    },

    produtividade() {
      return convertToTargetUnit(
        this.semeadura.colheita.produtividade,
        'performance'
      )
    },

    area() {
      return convertToTargetUnit(this.semeadura.area, 'area')
    },
  },

  methods: {
    ...mapMutations('semeaduras', ['setSemeadura']),

    async selecionaSemeadura() {
      this.setSemeadura(this.semeadura)

      this.$router.push({
        path: 'execucao',
        query: {
          semeadura_id: this.semeadura.id,
          fazenda_id: this.currentFarmId,
          start_period: this.currentSeasonCrop.start_period,
          end_period: this.currentSeasonCrop.end_period,
        },
      })
    },

    async excluir() {
      try {
        await this.$api.semeaduras.delete(this.semeadura.id)
        this.$emit('actionFinalized')

        this.$root.$emit(
          'notify',
          'success',
          this.$t('semeadura.excluir.sucesso')
        )
      } catch (e) {
        throw Error(e)
      }
    },

    openModalSowing() {
      this.$emit('edit-planting', this.semeadura)
    },

    openModalHarvest() {
      logEvent(events.harvests.clickedButton_registerSingleHarvest)
      this.openHarvestForm = true
    },

    harvest() {
      this.$emit('actionFinalized', this.currentFarmId)
    },
  },
}
</script>

<template>
  <v-card
    class="green darken-3 center white--text"
    height="200px"
    width="200px"
    @click="selecionaSemeadura()"
  >
    <v-responsive :aspect-ratio="1">
      <v-card-title class="pa-2">
        <v-chip color="#D8F2DC" v-if="semeadura.colheita && harvestLost">
          <span class="text-tag">
            <v-icon x-small color="#39AF49">mdi-alert</v-icon>
            {{ $t('harvest.lost') }}</span
          >
        </v-chip>
        <v-chip v-if="semeadura.colheita && !harvestLost" color="#D8F2DC">
          <span class="text-tag">
            {{ produtividade }} &nbsp;&nbsp;
            {{ $unitMeasures['performance'][$currentLanguage()] }}</span
          >
        </v-chip>

        <v-spacer></v-spacer>

        <v-menu v-if="showMenu && semeadura.plot_enabled" bottom left>
          <template #activator="{ on }">
            <v-btn dark icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-if="hasPermission($p.planting.edit) && enabledFarm"
              @click="openModalSowing"
            >
              <v-list-item-title>
                <div style="text-align: center">
                  <v-icon>mdi-pencil</v-icon> &nbsp;
                  {{ $t('editar') }}
                </div>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="hasPermission($p.harvest.create)"
              @click="openModalHarvest"
            >
              <v-list-item-title
                ><v-icon>mdi-sprout</v-icon> &nbsp;{{
                  $t('colheita.colheita')
                }}</v-list-item-title
              >
            </v-list-item>

            <v-divider
              v-if="hasPermission($p.planting.delete) && enabledFarm"
            ></v-divider>

            <v-list-item
              v-if="hasPermission($p.planting.delete) && enabledFarm"
              @click="excluir"
            >
              <v-list-item-title class="red--text"
                ><v-icon color="red">mdi-delete</v-icon> &nbsp;
                {{ $t('excluir') }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text
        class="float-left text-left d-block white--text card-text-position pa-2"
      >
        <v-row class="caption" justify="start" no-gutters>
          <v-col>{{ semeadura.data_semeadura.formatDate() }}</v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="font-weight-black">{{ semeadura.cultivar }}</v-col>
        </v-row>
        <v-row
          class="text--secundary mb-2"
          justify="start"
          align="center"
          no-gutters
        >
          <v-col cols="8">{{ semeadura.talhao }}</v-col>
          <v-col cols="4" class="caption text-right"
            >{{ area }} {{ $unitMeasures['area'][$currentLanguage()] }}</v-col
          >
        </v-row>
        <v-row
          no-gutters
          v-if="!semeadura.colheita && semeadura.ready_to_harvest"
        >
          <v-col cols="12">
            <v-row class="justify-center align-end" no-gutters>
              <v-btn
                v-if="
                  hasPermission($p.harvest.create) && semeadura.ready_to_harvest
                "
                outlined
                text
                class="black--text text-none button-register-harvest"
                @click.stop="openModalHarvest"
              >
                <span class="text-register-harvest">{{
                  $t('harvest.register_harvest')
                }}</span>
                <font-awesome-icon
                  class="pl-2 fa-155"
                  color="#39AF49"
                  icon="seedling"
                />
              </v-btn>
              <v-chip color="#D8F2DC" v-else>
                <font-awesome-icon
                  class="fa-07"
                  color="#39AF49"
                  icon="seedling"
                />
                <span class="ready_to_harvest_whithout_permission pl-1">
                  {{ $t('harvest.ready_to_harvest') }}
                </span>
              </v-chip>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-responsive>

    <modal-colheita
      v-if="openHarvestForm"
      @close="openHarvestForm = false"
      :dialog="openHarvestForm"
      @actionFinalized="harvest"
      :semeadura="semeadura"
    ></modal-colheita>
  </v-card>
</template>

<style scoped>
.text-tag {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #39af49;
}

.button-register-harvest {
  background: #fff;
}

.text-register-harvest {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.9px;
  color: #000;
}

.fa-155 {
  font-size: 1.55rem;
}
.fa-07 {
  font-size: 0.7rem;
}

.ready_to_harvest_whithout_permission {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #39af49;
}
</style>
