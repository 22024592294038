<script>
export default {
  props: {
    stadiumNotRecommended: { type: Boolean, default: true },
  },
}
</script>
<template>
  <v-alert
    v-if="stadiumNotRecommended"
    color="red"
    text
    dense
    type="error"
    class="alert_stadium"
  >
    {{ $t('execucao.estadio_nao_recomendado') }}
  </v-alert>
</template>
<style scoped>
.alert_stadium {
  font-size: 12px;
  width: 260px;
  white-space: pre-wrap;
}
</style>
