<script>
import { mapGetters, mapActions } from 'vuex'
import ModalIrrigacao from './ModalIrrigacao'
import { convertToTargetUnit } from '@/components/Form/converter'

export default {
  props: {
    hasColheita: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      menu_action_key: 0,
    }
  },

  computed: {
    ...mapGetters('execucoes', ['irrigacoes', 'isLoadingIrrigacoes']),
  },

  components: {
    ModalIrrigacao,
  },

  async created() {
    this.loadIrrigacoesSemeadura()
  },

  methods: {
    ...mapActions('execucoes', [
      'loadIrrigacoesSemeadura',
      'createIrrigacaoSemeadura',
      'updateIrrigacaoSemeadura',
      'deleteIrrigacaoSemeadura',
    ]),

    convertIrrigation(value) {
      return convertToTargetUnit(value, 'rainfall')
    },

    async saveIrrigacao(irrigacao) {
      if (irrigacao.id === null) await this.createIrrigacaoSemeadura(irrigacao)
      else await this.updateIrrigacaoSemeadura(irrigacao)

      this.$root.$emit('notify', 'success', this.$t('irrigacao.salva_sucesso'))
    },

    async deleteIrrigacao(irrigacao) {
      await this.deleteIrrigacaoSemeadura(irrigacao.id)
      this.$root.$emit(
        'notify',
        'success',
        this.$t('irrigacao.excluida_sucesso')
      )
    },
    fecharMenu() {
      this.menu_action_key++
    },
  },
}
</script>
<template>
  <v-container>
    <v-row>
      <v-col class="ml-4 mt-4">
        <div class="text-h5">
          <v-icon class="mr-2 mb-2">mdi-watering-can-outline</v-icon>
          {{ $t('irrigacao.irrigacao') }}
        </div>
        <span></span>
      </v-col>
    </v-row>
    <v-divider />
    <v-simple-table>
      <tbody v-if="isLoadingIrrigacoes" class="text_align_center">
        <tr class="mt-2">
          <td>
            <div class="title">{{ $t('loading') }}</div>
          </td>
        </tr>
      </tbody>
      <tbody
        v-else-if="irrigacoes && irrigacoes.length === 0"
        class="text_align_center"
      >
        <tr class="mt-2">
          <td>
            <div class="subtitle">{{ $t('irrigation.no_irrigation') }}</div>
          </td>
        </tr>
      </tbody>
      <tbody v-else class="text_align_center">
        <tr v-for="irrigacao in irrigacoes" :key="irrigacao.id" class="mt-2">
          <td>
            <div>{{ irrigacao.inicio.formatDate() }}</div>
            <div>
              <b>{{ moment(irrigacao.inicio).format('HH:mm') }}</b>
            </div>
          </td>
          <td>
            <v-icon>mdi-arrow-right-thick</v-icon>
          </td>
          <td>
            <div>{{ irrigacao.fim.formatDate() }}</div>
            <div>
              <b>{{ moment(irrigacao.fim).format('HH:mm') }}</b>
            </div>
          </td>
          <td>
            <span
              >{{ convertIrrigation(irrigacao.lamina_mm) }}
              {{ $unitMeasures['rainfall'][$currentLanguage()] }}</span
            >
            <v-icon>mdi-water-outline</v-icon>
          </td>
          <td style="width: 10%">
            <v-menu
              bottom
              left
              :close-on-click="true"
              :key="menu_action_key"
              :disabled="hasColheita"
            >
              <template #activator="{ on }">
                <v-btn dark icon v-on="on">
                  <v-icon class="dots_color">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <modal-irrigacao
                  :irrigacao="irrigacao"
                  @saveIrrigacao="saveIrrigacao"
                  @fecharMenu="fecharMenu()"
                >
                  <template #activator="{ on }">
                    <v-list-item v-on="on">
                      <v-list-item-title>
                        <div style="text-align: center">
                          <v-icon>mdi-pencil</v-icon> &nbsp;
                          {{ $t('editar') }}
                        </div>
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </modal-irrigacao>
                <v-divider></v-divider>
                <v-list-item @click="deleteIrrigacao(irrigacao)">
                  <v-list-item-title class="red--text">
                    <v-icon color="red">mdi-delete</v-icon> &nbsp;
                    {{ $t('excluir') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <div class="mt-4 text_align_center">
      <modal-irrigacao
        @saveIrrigacao="saveIrrigacao"
        @fecharMenu="fecharMenu()"
      >
        <template #activator="{ on }">
          <v-btn
            color="primary"
            style="border-radius: 35px"
            v-on="on"
            :loading="isLoadingIrrigacoes"
            :disabled="hasColheita"
          >
            <v-icon class="mr-2">mdi-water-plus-outline</v-icon>
            {{ $t('adicionar') }}
          </v-btn>
        </template>
      </modal-irrigacao>
    </div>
  </v-container>
</template>
<style scoped>
.circle_background_color {
  background-color: #c8c8c8;
}
.text_align_center {
  text-align: center;
}
.dots_color {
  color: #c8c8c8 !important;
}
</style>
