<script>
import { mapGetters, mapActions } from 'vuex'
import SprayingModal from '@/components/Spraying/SprayingModal'
import IrrigacaoList from './IrrigacaoList'
import EstadioFenologico from './EstadioFenologico'
import PermissionMixin from '@/components/Permission/PermissionMixin'
import SprayingItem from './SprayingItem'
import TotalSprayingsInfo from '@/components/Program/TotalSprayingsInfo.vue'

export default {
  mixins: [PermissionMixin],

  components: {
    SprayingItem,
    IrrigacaoList,
    EstadioFenologico,
    SprayingModal,
    TotalSprayingsInfo,
  },

  computed: {
    ...mapGetters('execucoes', [
      'execucoes',
      'programa',
      'isLoadingExecucoes',
      'semeadura',
    ]),
    ...mapGetters('crops', ['maxSprayingsByCurrentCrop']),
    ...mapGetters('farms', ['currentFarm', 'enabledFarm']),
    ...mapGetters('user', ['isUSA']),

    newExecucaoIndex() {
      return this.execucoes.length + 1
    },
    hasColheita() {
      return !!this.semeadura.colheita
    },
    estadioAtual() {
      return {
        semeadura_id: this.semeadura.id,
        estadio: this.semeadura.estadio_atual,
      }
    },
    allowNovaAplicacao() {
      return this.execucoes?.length < this.maxSpraying
    },
    bagPrice() {
      const bag = this.currentFarm?.bag_price?.find(
        (bag) => bag.crop_id === this.semeadura.crop_id
      )
      return bag?.price ?? 0
    },
    maxSpraying() {
      return this.isUSA
        ? process.env.VUE_APP_USA_MAX_APLICACOES
        : this.maxSprayingsByCurrentCrop
    },
  },

  async mounted() {
    this.checkNavBtnStatus()
    await this.fetchDefensivesLastData()

    if (this.programa.id) {
      await this.fetchDefensives({
        farmId: parseInt(this.$route.query.fazenda_id),
        programId: this.programa.id,
      })
    }
  },
  methods: {
    ...mapActions('defensives', ['fetchDefensives', 'fetchDefensivesLastData']),

    isStatusDoing(execucao) {
      const firstTodoExecucoes = this.execucoes.filter(
        (execucao) => !execucao.data_realizada
      )[0]
      return execucao.id === firstTodoExecucoes.id
    },

    isLastDone(execucao) {
      const doneExecucoes = this.execucoes.filter(
        (execucao) => !!execucao.data_realizada
      )
      return execucao.id === doneExecucoes[doneExecucoes.length - 1].id
    },

    checkNavBtnStatus() {
      this.previousButton = document.querySelector('.v-slide-group__prev')
      this.nextButton = document.querySelector('.v-slide-group__next')
    },

    clickPreviousButton() {
      this.previousButton.click()
      this.checkNavBtnStatus()
    },

    clickNextButton() {
      this.nextButton.click()
      this.checkNavBtnStatus()
    },
  },
}
</script>

<template>
  <div>
    <v-row class="d-sm-none">
      <v-col class="text-left">
        <v-btn
          fab
          dark
          color="blue-grey darken-3 mr-2"
          height="40px"
          width="40px"
          @click="clickPreviousButton"
        >
          <v-icon dark>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
          fab
          dark
          color="blue-grey darken-3 ml-2"
          height="40px"
          width="40px"
          @click="clickNextButton"
        >
          <v-icon dark>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="pa-0">
        <v-slide-group class="mt-2" mobile-breakpoint="xs">
          <template #prev>
            <v-row class="align-start" style="height: 100%">
              <v-row class="align-center" style="height: 500px">
                <v-btn
                  fab
                  dark
                  color="blue-grey darken-3 mr-2"
                  height="40px"
                  width="40px"
                >
                  <v-icon dark>mdi-chevron-left</v-icon>
                </v-btn>
              </v-row>
            </v-row>
          </template>

          <template #next>
            <v-row class="align-start" style="height: 100%">
              <v-row class="align-center" style="height: 500px">
                <v-btn
                  fab
                  dark
                  color="blue-grey darken-3 ml-2"
                  height="40px"
                  width="40px"
                >
                  <v-icon dark>mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </v-row>
          </template>

          <v-row
            v-if="isLoadingExecucoes"
            align-content="center"
            justify="center"
          >
            <v-col cols="12" class="subtitle-1 text-center">
              {{ $t('loading') }}
            </v-col>
            <v-col cols="12" class="text-center">
              <v-progress-circular
                indeterminate
                color="blue"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-slide-item
            v-else
            class="mx-2 v-slide-item--custom"
            v-for="(execucao, i) in execucoes"
            :key="execucao.id"
          >
            <spraying-item
              v-if="execucao.data_realizada != null"
              type="done"
              :spraying="execucao"
              :last-done="isLastDone(execucao)"
              :harvested="hasColheita"
              :bag-price="bagPrice"
            ></spraying-item>
            <spraying-item
              v-else-if="isStatusDoing(execucao)"
              type="doing"
              :spraying="execucao"
              :last-done="i == execucoes.length - 1"
              :harvested="hasColheita"
              :bag-price="bagPrice"
            ></spraying-item>
            <spraying-item
              v-else-if="execucao.data_realizada == null"
              type="todo"
              :spraying="execucao"
              :last-done="i == execucoes.length - 1"
              :harvested="hasColheita"
              :bag-price="bagPrice"
            ></spraying-item>
          </v-slide-item>
          <v-slide-item
            v-if="allowNovaAplicacao && !isLoadingExecucoes && !hasColheita"
            :key="`novaExecucao_${execucoes.length + 1}`"
          >
            <div>
              <v-row justify="center">
                <v-col>
                  <spraying-modal :aplicacao="newExecucaoIndex" v-if="programa">
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="mx-2 align-center"
                        fab
                        dark
                        color="green darken-1"
                        height="50px"
                        width="50px"
                        v-bind="attrs"
                        v-on="on"
                        style="left: 50%; transform: translateX(-65%)"
                      >
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                      <p class="text--secondary caption mt-1 text-center">
                        {{ $t('aplicacao.adicionar_aplicacao') }}
                      </p>
                    </template>
                  </spraying-modal>
                </v-col>
              </v-row>
              <total-sprayings-info :program="programa"></total-sprayings-info>
            </div>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        lg="6"
        xl="6"
        md="7"
        sm="12"
        xs="12"
        v-if="hasPermission($p.program.create) && enabledFarm"
      >
        <estadio-fenologico
          :estadio-atual="estadioAtual"
          :has-colheita="hasColheita"
        ></estadio-fenologico>
      </v-col>

      <v-col
        lg="6"
        xl="6"
        md="7"
        sm="12"
        xs="12"
        v-if="hasPermission($p.program.create) && enabledFarm"
      >
        <irrigacao-list
          :semeaura_id="programa.semeadura_id"
          :has-colheita="hasColheita"
        ></irrigacao-list>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.v-slide-item--custom {
  width: 295px;
}
</style>
