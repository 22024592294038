<script>
import { mapGetters, mapActions } from 'vuex'
import growthStage from '@/services/growthStage.js'
import ModalEstadioFenologico from '@/pages/execucao/ModalEstadioFenologico.vue'

export default {
  name: 'EstadioFenologico',

  props: {
    estadioAtual: {
      type: Object,
      default: null,
    },
    hasColheita: {
      type: Boolean,
      default: false,
    },
  },

  async created() {
    await this.getGrowthStages()
  },

  data() {
    return {
      barHeight: 10,
      estadios: [],
      standardBgColor: 'grey lighten-2',
    }
  },

  components: {
    ModalEstadioFenologico,
  },

  computed: {
    ...mapGetters('crops', ['isSoybean']),
    ...mapGetters('execucoes', ['programa']),
    ...mapGetters('farms', ['currentFarmId']),
    allGrowthStages() {
      return [
        ...this.progressByCrop.vegetative,
        ...this.progressByCrop.reproductiveInitial,
        ...this.progressByCrop.reproductiveFinal,
        ...this.progressByCrop.maturation,
      ]
    },
    currentGrowthStageIndex() {
      return this.allGrowthStages.findIndex(
        (stage) => stage === this.estadioAtual?.estadio?.name
      )
    },
    progressByCrop() {
      return this.isSoybean
        ? growthStage.soybean.progressSteps
        : growthStage.wheat.progressSteps
    },
    progressEmergence() {
      return 100
    },
    progressVegetative() {
      const vegetativeSteps = this.progressByCrop['vegetative']
      const lastVegetativeIndex = this.allGrowthStages.findIndex(
        (stage) => stage === vegetativeSteps[vegetativeSteps.length - 1]
      )
      if (lastVegetativeIndex < this.currentGrowthStageIndex) {
        return 100
      }
      return this.calculateProgress(
        this.estadioAtual?.estadio?.name,
        vegetativeSteps
      )
    },
    progressReproductiveInitial() {
      const reproductiveInitialSteps =
        this.progressByCrop['reproductiveInitial']
      const lastReproductiveInitialIndex = this.allGrowthStages.findIndex(
        (stage) =>
          stage ===
          reproductiveInitialSteps[reproductiveInitialSteps.length - 1]
      )
      if (lastReproductiveInitialIndex < this.currentGrowthStageIndex) {
        return 100
      }
      return this.calculateProgress(
        this.estadioAtual?.estadio?.name,
        reproductiveInitialSteps
      )
    },
    progressReproductiveFinal() {
      const reproductiveFinalSteps = this.progressByCrop['reproductiveFinal']
      const lastReproductiveFinalIndex = this.allGrowthStages.findIndex(
        (stage) =>
          stage === reproductiveFinalSteps[reproductiveFinalSteps.length - 1]
      )
      if (lastReproductiveFinalIndex < this.currentGrowthStageIndex) {
        return 100
      }
      return this.calculateProgress(
        this.estadioAtual?.estadio?.name,
        reproductiveFinalSteps
      )
    },
    progressMaturation() {
      const maturationSteps = this.progressByCrop['maturation']
      return this.calculateProgress(
        this.estadioAtual?.estadio?.name,
        maturationSteps
      )
    },
  },

  methods: {
    ...mapActions('execucoes', ['loadPrograma', 'loadSemeaduras']),
    openModal() {
      this.$refs.modal.open()
    },
    calculateProgress(growthStage, steps) {
      const stageIndex = steps.findIndex((stage) => stage == growthStage)
      if (!stageIndex) return 0
      const lastIndex = steps.length - 1
      return parseInt(((stageIndex / lastIndex) * 100).toFixed(0))
    },
    async updateGrowthStage() {
      await this.loadSemeaduras(this.currentFarmId)
      await this.loadPrograma()
    },
    async getGrowthStages() {
      const programId = this.programa.id
      try {
        const { data } = await this.$api.semeaduras.estadios.getList(programId)
        this.estadios = data
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>
<template>
  <v-container>
    <v-row class="ml-4 mt-4">
      <v-col>
        <div class="text-h5">
          <v-icon class="mr-2 mb-2">mdi-sprout</v-icon>
          {{ $t('stage.growth_stage') }}
        </div>
      </v-col>
    </v-row>
    <v-divider />
    <v-row>
      <v-col>
        <v-row no-gutters>
          <v-col cols="1">
            <v-progress-linear
              :value="progressEmergence"
              :background-color="standardBgColor"
              color="brown lighten-2"
              :height="barHeight"
            >
              <strong class="subtitle-1"></strong>
            </v-progress-linear>
          </v-col>
          <v-col cols="3">
            <v-progress-linear
              :value="progressVegetative"
              :background-color="standardBgColor"
              color="green darken-2"
              :height="barHeight"
            />
          </v-col>
          <v-col cols="3">
            <v-progress-linear
              :value="progressReproductiveInitial"
              :background-color="standardBgColor"
              color="green lighten-2"
              :height="barHeight"
            />
          </v-col>
          <v-col cols="3">
            <v-progress-linear
              :value="progressReproductiveFinal"
              :background-color="standardBgColor"
              color="green lighten-3"
              :height="barHeight"
            />
          </v-col>
          <v-col cols="2">
            <v-progress-linear
              :value="progressMaturation"
              :background-color="standardBgColor"
              color="amber"
              :height="barHeight"
            />
          </v-col>
        </v-row>
        <span class="subtitle-1 text--center">
          {{ $t('stage.real_stage') }}:
          <b v-if="hasColheita">{{ $t('harvest.harvested') }}</b>
          <span v-else>{{ $t(estadioAtual.estadio.message_key) }}</span>

          <v-btn v-if="!hasColheita" text @click="openModal" small>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </span>
      </v-col>
    </v-row>
    <modal-estadio-fenologico
      ref="modal"
      :estadio="estadioAtual"
      :estadios="estadios"
      @alterou="updateGrowthStage"
    />
  </v-container>
</template>
