<script>
import moment from 'moment'

export default {
  props: {
    estadio: {
      type: Object,
      required: true,
    },
    estadios: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      observed_growth_stage_id: null,
      observado_em: null,
      saving: false,
    }
  },

  watch: {
    dialog(value) {
      if (value) {
        this.observed_growth_stage_id = this.estadio.estadio?.id
        this.observado_em = this.estadio.estadio?.observado_em
      }
    },
  },

  methods: {
    // accessed by refs
    // eslint-disable-next-line vue/no-unused-properties
    open() {
      this.dialog = true
    },

    close() {
      this.dialog = false
    },

    async save() {
      const estadio = {
        semeadura_id: this.estadio.semeadura_id,
        observed_growth_stage_id: this.observed_growth_stage_id,

        // TODO: alterar quando liberar o input de data observada
        observado_em: moment().toDate(),
      }
      this.saving = true
      try {
        await this.$api.semeaduras.estadios.create(estadio)
        this.$emit('alterou', estadio)
        this.close()
        this.$root.$emit(
          'notify',
          'success',
          this.$t('growth_stage.save_sucess')
        )
      } catch {
        this.$root.$emit(
          'notify',
          'error',
          this.$t('growth_stage.save_failure')
        )
      } finally {
        this.saving = false
      }
    },
  },
}
</script>

<template>
  <v-dialog v-model="dialog" max-width="300px" :persistent="saving">
    <v-card>
      <v-card-title
        class="headline blue-grey darken-2 white--text"
        primary-title
      >
        {{ $t('stage.growth_stage') }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              v-model="observed_growth_stage_id"
              :items="estadios"
              :item-text="(item) => $t(item.message_key)"
              item-value="id"
              :disabled="saving"
              :label="$t('stage.stages')"
              max-width="100px"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="grey" :disabled="saving" @click="close">
          {{ $t('cancelar') }}
        </v-btn>
        <v-btn color="primary" :loading="saving" @click="save">
          {{ $t('salvar') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
