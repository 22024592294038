<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { convertToTargetUnit } from '@/components/Form/converter'

export default {
  props: {
    bagPrice: {
      type: Number,
      default: null,
    },
  },

  computed: {
    ...mapGetters('execucoes', ['semeadura', 'programa', 'execucoes']),
    ...mapGetters('farms', ['currentFarm']),

    totalDelay() {
      return this.execucoes
        .map((execucao) =>
          execucao?.data_realizada > execucao?.data_ideal
            ? moment(execucao.data_realizada).diff(execucao.data_ideal, 'days')
            : 0
        )
        .reduce((total, days) => total + days, 0)
        .toFixed(0)
    },

    custoTotal() {
      if (!this.execucoes) return 0

      const precoFloat = (d) => (d.preco !== null ? d.preco * d.dose : 0)
      const pegaPrecos = (p) => p.execucao_defensivos.map(precoFloat)
      const flatten = (acumulador, precos) => acumulador.concat(precos)
      const somaTudo = (preco1, preco2) => preco1 + preco2
      return this.execucoes
        .map(pegaPrecos)
        .reduce(flatten, [])
        .reduce(somaTudo, 0)
        .toFixed(2)
    },

    custoTotalSc() {
      const bag = this.currentFarm?.bag_price?.find(
        (bag_price) => bag_price.crop_id == this.semeadura.crop_id
      )
      return convertToTargetUnit(this.custoTotal / bag.price, 'weight')
    },
  },
}
</script>

<template>
  <v-container class="px-0">
    <v-row justify="center">
      <v-col cols="12" md="11" class="px-0">
        <v-card class="grey lighten-3 grow text-center">
          <v-row align="center" dense>
            <v-col cols="6" md="3">
              <v-row align="center" justify="center">
                <v-col md="12">
                  <div class="title text-left pl-4">
                    <i>{{ programa.nome }}</i>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="6" md="3">
              <div class="body-2" v-if="semeadura.data_semeadura">
                <span v-if="semeadura">
                  {{
                    $t('semeadura_em', [semeadura.data_semeadura.formatDate()])
                  }}
                </span>
              </div>
            </v-col>

            <v-col cols="6" md="3">
              <div class="body-2" v-if="semeadura.data_emergencia">
                <span v-if="semeadura">
                  {{ $t('emergencia_em') }}
                  <strong>{{
                    semeadura.data_emergencia.formatDate()
                  }}</strong></span
                >
              </div>
            </v-col>

            <v-col cols="6" md="3">
              <div class="subtitle">
                <span>
                  <small> {{ $t('custo_total_hectares') }} </small>
                </span>
                <div>
                  <strong>{{ $currency(custoTotal) }}</strong>
                  {{
                    bagPrice
                      ? custoTotalSc +
                        $unitMeasures['weight'][$currentLanguage()]
                      : ''
                  }}
                </div>
              </div>
            </v-col>
          </v-row>
          <v-card-actions
            v-if="totalDelay > 0"
            class="pa-0 ma-0"
            style="overflow: hidden"
          >
            <v-row dense>
              <v-col class="yellow darken-1">
                <span class="caption">
                  {{ $t('execucao.total_delay', [totalDelay]) }}
                </span>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
