<template>
  <div class="ml-2">
    <permission-failed-message v-if="!hasPermission($p.planting.list)" />
    <div v-else :class="isMobile ? 'ml-3' : ''">
      <semeaduras-list />
    </div>
  </div>
</template>

<script>
import LayoutMixin from '@/components/LayoutMixin.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import SemeadurasList from '@/pages/semeaduras/List.vue'

export default {
  name: 'SowingHome',

  mixins: [PermissionMixin, LayoutMixin],

  components: {
    SemeadurasList,
  },
}
</script>
