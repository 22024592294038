<template>
  <v-slide-item v-slot="{ toggle }">
    <div class="overflow-y-auto" scrollable>
      <spraying-modal
        ref="modalAplicacao"
        :aplicacao="spraying.aplicacao"
      ></spraying-modal>

      <v-card
        class="grey lighten-3 center white--text mb-4"
        shaped
        @click="toggle"
      >
        <v-card-title class="pa-2" :class="[cardColorClass]">
          <v-row v-if="isDone" no-gutters align="center" justify="center">
            <v-col md="1">
              <v-icon color="white">mdi-check-circle</v-icon>
            </v-col>

            <v-col md="9" cols="9" lg="9" xl="9">
              <div class="body-2 text-center pl-2">
                {{ $t('aplicado_em') }}
                <strong>{{ spraying.data_realizada.formatDate() }}</strong>
              </div>
            </v-col>

            <v-col md="1">
              <spraying-menu
                :type="type"
                :disabled="harvested"
                :is-last="lastDone"
                @edit-defensive="openModalAplicacao"
                @undo-defensive="undo"
                @delete-defensive="excluir(spraying)"
              ></spraying-menu>
            </v-col>
          </v-row>

          <v-row v-else-if="isDoing" no-gutters align="center" justify="center">
            <v-col cols="6" class="body-1 font-weight-bold text-right pr-1">{{
              $t('data_ideal')
            }}</v-col>
            <v-col cols="4" class="body-2 text-left">{{
              spraying.data_ideal.formatDate()
            }}</v-col>
            <v-col cols="2">
              <spraying-menu
                :type="type"
                :disabled="harvested"
                :is-last="lastDone"
                @edit-defensive="openModalAplicacao"
                @undo-defensive="undo"
                @delete-defensive="excluir(spraying)"
              ></spraying-menu>
            </v-col>
          </v-row>

          <v-row v-else-if="isTodo" no-gutters align="center" justify="center">
            <v-col md="1">
              <v-icon color="white">mdi-clock</v-icon>
            </v-col>
            <v-col md="9" cols="9" lg="9" xl="9">
              <div class="caption text-center pl-2">
                {{ $t('data_ideal') }}
                <strong>{{ spraying.data_ideal.formatDate() }}</strong>
              </div>
            </v-col>
            <v-col md="1">
              <spraying-menu
                :type="type"
                :disabled="harvested"
                :is-last="lastDone"
                @edit-defensive="openModalAplicacao"
                @undo-defensive="undo"
                @delete-defensive="excluir(spraying)"
              ></spraying-menu>
            </v-col>
          </v-row>

          <program-days-later
            :class="isDoing ? 'mb-2' : ''"
            :execution="spraying"
          ></program-days-later>
        </v-card-title>

        <v-row v-if="!harvested && isDoing" justify="center" class="mt-n6">
          <v-col cols="auto">
            <modal-aplicar :execucao="spraying">
              <template #activator="{ on }">
                <v-btn
                  class="mx-2"
                  rounded
                  dark
                  color="green darken-1"
                  height="40px"
                  width="200px"
                  v-on="on"
                >
                  {{ $t('aplicacao.aplicar') }}
                </v-btn>
              </template>
            </modal-aplicar>
          </v-col>
        </v-row>

        <br v-if="harvested" />

        <v-card-text
          class="float-center text-center d-block gray--text text--darken-2"
          :class="isTodo || isDone ? 'pa-1' : ' pa-2 mt-n6'"
        >
          <v-row align="center" justify="center">
            <v-col
              v-for="produto in spraying.execucao_defensivos.slice(0, 3)"
              :key="produto.id"
              cols="12"
              md="6"
            >
              <div class="font-weight-bold">{{ produto.defensivo.nome }}</div>
              <div class="caption">
                {{ produto.dose }} &nbsp;
                {{ produto.defensivo.unidade_medida.replace('_', ' ') }}
              </div>
            </v-col>

            <!-- Se houver mais de 4 produtos, mostra quantos produtos não couberam na tela 
              e coloca tooltip para mostrar eles -->
            <v-col
              v-if="spraying.execucao_defensivos.length > 4"
              cols="12"
              md="6"
            >
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <div v-on="on" class="title font-weight-bold">
                    + {{ spraying.execucao_defensivos.length - 3 }}
                  </div>
                </template>

                <div
                  v-for="produto in spraying.execucao_defensivos.slice(3)"
                  :key="'tooltip_' + produto.id"
                >
                  {{ `${produto.defensivo.nome}(${produto.dose})` }}
                </div>
              </v-tooltip>
            </v-col>

            <!-- Senão, mostra o quarto produto -->
            <v-col
              v-else-if="spraying.execucao_defensivos.length === 4"
              cols="12"
              md="6"
            >
              <div class="font-weight-bold">
                {{ spraying.execucao_defensivos[3].defensivo.nome }}
              </div>
              <div class="caption">
                {{ spraying.execucao_defensivos[3].dose }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-row justify="center" align="center">
        <v-col cols="2"></v-col>
        <v-col
          cols="4"
          class="spraying-item__total-cost body-1 font-weight-bold text-right pr-1"
        >
          {{ $currency(custo) }}
        </v-col>
        <v-col class="body-2 text-left">
          {{
            bagPrice
              ? custoSc + $unitMeasures['weight'][$currentLanguage()]
              : ''
          }}
        </v-col>
        <v-col cols="2" class="mr-4">
          <tooltip-efficacy
            :without-register="semRegistro(spraying)"
          ></tooltip-efficacy>
        </v-col>
      </v-row>

      <div v-if="isDoing">
        <programa-list-item-doing-previsao-tempo
          :key="`meteorologia_${spraying.id}`"
        ></programa-list-item-doing-previsao-tempo>

        <div class="d-flex justify-center" no-gutters>
          <efficacies
            class="d-inline-flex"
            v-if="spraying.eficacias"
            :spraying="spraying"
            :mobile="$vuetify.breakpoint.xs"
          />
          <div class="d-flex caption">
            <severities-labels
              :efficacies="spraying.eficacias"
            ></severities-labels>
          </div>
        </div>
      </div>

      <v-row v-if="isTodo" class="d-flex justify-center" no-gutters>
        <v-col v-if="spraying.eficacias">
          <efficacies :spraying="spraying" />
        </v-col>
      </v-row>

      <v-row v-if="isDone" justify="center" no-gutters>
        <v-col v-if="spraying.eficacias">
          <efficacies :spraying="spraying" />
        </v-col>
      </v-row>

      <v-row v-if="isDoing || isTodo">
        <v-col>
          <alert-estadium
            v-if="$toggle('last_spray_feedback')"
            :stadium-not-recommended="spraying.estadio_nao_recomendado"
          ></alert-estadium>
        </v-col>
      </v-row>
    </div>
  </v-slide-item>
</template>

<script>
import { mapActions } from 'vuex'

import SprayingModal from '@/components/Spraying/SprayingModal.vue'
import SeveritiesLabels from '@/components/Spraying/SeveritiesLabels.vue'
import ModalAplicar from '@/pages/execucao/ModalAplicar.vue'
import SprayingMenu from '@/pages/execucao/SprayingMenu.vue'
import ProgramaListItemDoingPrevisaoTempo from '@/pages/execucao/ProgramaListItemDoingPrevisaoTempo.vue'
import ProgramDaysLater from '@/pages/execucao/ProgramDaysLater.vue'
import AlertEstadium from '@/pages/execucao/AlertEstadium.vue'
import TooltipEfficacy from '@/pages/execucao/TooltipEfficacy.vue'
import Efficacies from '@/components/Efficacies/index.vue'

export default {
  components: {
    SprayingMenu,
    SprayingModal,
    ModalAplicar,
    ProgramaListItemDoingPrevisaoTempo,
    ProgramDaysLater,
    AlertEstadium,
    TooltipEfficacy,
    SeveritiesLabels,
    Efficacies,
  },
  props: {
    spraying: {
      type: Object,
      default: null,
    },
    lastDone: {
      type: Boolean,
      default: false,
    },
    harvested: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'todo',
    },
    bagPrice: {
      type: Number,
      default: null,
    },
  },
  computed: {
    isDoing() {
      return this.type == 'doing'
    },

    isDone() {
      return this.type == 'done'
    },

    isTodo() {
      return this.type == 'todo'
    },

    cardColorClass() {
      if (this.isDone) {
        return 'green darken-1'
      }
      if (this.isDoing) {
        return 'blue-grey darken-3'
      }
      return 'grey darken-2'
    },

    custo() {
      const precoFloat = (d) => (d.preco !== null ? d.preco * d.dose : 0)
      const somaTudo = (preco1, preco2) => preco1 + preco2
      return this.spraying.execucao_defensivos
        .map(precoFloat)
        .reduce(somaTudo, 0)
        .toFixed(2)
    },

    custoSc() {
      return (this.custo / this.bagPrice).toFixed(2)
    },
  },

  methods: {
    ...mapActions('execucoes', ['deleteExecucao', 'unapplySpray']),

    semRegistro(spraying) {
      return Boolean(
        spraying.eficacias.find((eficacia) => eficacia.sem_registro)
      )
    },

    excluir() {
      try {
        this.deleteExecucao(this.spraying)
      } catch (error) {
        this.$root.$emit('notify', 'error', 'Erro ao excluir aplicação')
      }
    },

    openModalAplicacao() {
      this.$refs.modalAplicacao.open()
    },

    async undo() {
      await this.unapplySpray({
        programId: this.spraying.programa_id,
        ordinal: this.spraying.aplicacao,
      })
    },
  },
}
</script>

<style scoped>
.v-card__text {
  padding: 0px;
}
.v-alert {
  padding: 4px;
}
</style>
