<script>
import ModalSemeadura from '@/pages/semeaduras/ModalSemeadura.vue'
import ModalColheita from '@/pages/execucao/ModalColheita.vue'
import ModalVideo from '@/components/ModalVideo.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import ExportPrescriptionBtn from '@/components/Buttons/Integrations/ExportPrescriptionBtn.vue'
import { getCropNameById } from '@/utils/crops.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ExecucaoHeader',

  mixins: [PermissionMixin],

  props: {
    showHarvestBtn: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      vSemeadura: null,
      urlAjuda: 'https://www.youtube.com/embed/nrQBJOu0s1I',
      openPlantingModal: false,
      isOpenModalHarvest: false,
    }
  },

  components: {
    ModalSemeadura,
    ModalColheita,
    ModalVideo,
    ExportPrescriptionBtn,
  },

  computed: {
    ...mapGetters('execucoes', [
      'fazenda',
      'semeadura',
      'programa',
      'execucoes',
    ]),
    ...mapGetters('farms', ['currentFarm', 'currentFarmId', 'enabledFarm']),
    ...mapGetters('user', ['isUSA']),
    ...mapGetters('crops', ['currentCrop']),
    ...mapGetters('semeaduras', ['semeaduras']),

    showMenu() {
      return (
        this.hasPermission(this.$p.planting.edit) ||
        this.hasPermission(this.$p.planting.delete) ||
        this.hasPermission(this.$p.harvest.create)
      )
    },

    populacaoMin() {
      return this.semeadura.cultivar_regiao_parametros?.populacao_min ?? ''
    },

    populacaoMax() {
      return this.semeadura.cultivar_regiao_parametros?.populacao_max ?? ''
    },

    ciclo() {
      return this.semeadura?.estimated_cycle ?? ''
    },

    minimumHarvestDate() {
      if (!this.execucoes) return null

      const reverseExecutions = [...this.execucoes].reverse()

      const lastApplication = reverseExecutions.find(
        (execution) => execution.data_realizada != null
      )
      return lastApplication?.data_realizada ?? this.semeadura.data_emergencia
    },

    sowingsAndCropHeaders() {
      return this.semeaduras
        .filter(({ semente }) => semente.crop_id == this.currentCrop.id)
        .reduce((acc, planting) => {
          const currentCropId = planting.semente.crop_id
          if (!acc.some((i) => i?.crop_id === currentCropId)) {
            const cropName = getCropNameById(currentCropId)
            acc.push({ header: this.$t(cropName) })
          }
          acc.push(planting)
          return acc
        }, [])
    },

    tutorial_toggle() {
      return this.$toggle('tutorial_enabled') && !this.isUSA
    },
  },

  mounted() {
    this.vSemeadura = this.semeadura
  },

  methods: {
    ...mapActions('execucoes', [
      'changeSemeadura',
      'deletePrograma',
      'loadSemeaduras',
    ]),
    async trocarSemeadura(semeadura) {
      await this.changeSemeadura(semeadura)

      this.$router.replace({
        query: { semeadura_id: semeadura.id, fazenda_id: this.currentFarmId },
      })
    },
    async unlinkPrograma() {
      await this.deletePrograma()
    },
    openPlantingForm(planting) {
      this.plantingSelected = planting
      this.openPlantingModal = true
    },
    closePlantingForm() {
      this.$emit('changed')
      this.plantingSelected = null
      this.openPlantingModal = false
    },
    openModalHarvest() {
      this.isOpenModalHarvest = true
    },
    async colheita() {
      this.isOpenModalHarvest = false
      await this.loadSemeaduras(this.currentFarmId)
    },
  },
}
</script>

<template>
  <v-col cols="12">
    <div>
      <v-row justify="center">
        <v-col cols="1"></v-col>

        <v-col cols="12" md="6">
          <v-row dense class="pb-1">
            <v-col cols="12" md="10" class="d-inline-flex pl-7">
              <v-select
                v-model="vSemeadura"
                :items="sowingsAndCropHeaders"
                item-value="id"
                :label="$t('semeadura.semeadura')"
                hide-details
                single-line
                return-object
                @change="trocarSemeadura"
              >
                <template #selection="{ item }">
                  {{ item.talhao }} - {{ item.cultivar }} -
                  {{ item.data_semeadura.formatDate() }}
                </template>

                <template #item="{ item }">
                  {{ item.talhao }} - {{ item.cultivar }} -
                  {{ item.data_semeadura.formatDate() }}
                </template>
              </v-select>
              <v-col align="left" v-if="tutorial_toggle">
                <modal-video :url-video="urlAjuda" />
              </v-col>
            </v-col>
          </v-row>

          <v-row class="mt-0" dense>
            <v-col
              cols="12"
              md="4"
              class="pa-1"
              v-if="semeadura && semeadura.semente"
            >
              <div class="subtitle-2">{{ semeadura.semente.asset_owner }}</div>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="pa-1"
              v-if="semeadura && semeadura.cultivar_regiao_parametros && !isUSA"
            >
              <div class="body-2">
                <small>
                  {{ $t('cultivar.populacao') }}:
                  {{
                    $t('cultivar.populacao_entre', [populacaoMin, populacaoMax])
                  }}
                </small>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="pa-1"
              v-if="semeadura && semeadura.cultivar_regiao_parametros"
            >
              <div class="body-2">
                <small>
                  {{ $t('cultivar.ciclo') }}:
                  <b>{{ $t('cultivar.ciclo_dias', [ciclo]) }}</b>
                </small>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <export-prescription-btn></export-prescription-btn>
        </v-col>
        <v-col
          cols="2"
          v-if="showHarvestBtn && !$vuetify.breakpoint.xs"
          class="text-right"
        >
          <v-btn outlined color="success" @click="openModalHarvest">
            <v-icon left>mdi-sprout</v-icon>
            &nbsp;{{ $t('colheita.colheita') }}
          </v-btn>
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.xs" cols="auto">
          <v-menu v-if="showMenu" bottom left>
            <template #activator="{ on }">
              <v-btn dark icon v-on="on">
                <v-icon color="blue-grey darken-2">
                  mdi-dots-horizontal
                </v-icon>
              </v-btn>
            </template>
            <v-list v-if="semeadura && currentFarm">
              <v-list-item
                v-if="hasPermission($p.planting.edit) && enabledFarm"
                link
              >
                <v-list-item-title @click="openPlantingForm(semeadura)">
                  <v-icon>mdi-pencil</v-icon> &nbsp; {{ $t('editar') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="programa && !semeadura.colheita"
                @click="unlinkPrograma"
              >
                <v-list-item-title
                  ><v-icon>mdi-close-circle</v-icon>
                  &nbsp;
                  {{ $t('programa.desassociar') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="hasPermission($p.harvest.create) && enabledFarm"
                @click="openModalHarvest"
              >
                <v-list-item-title
                  ><v-icon>mdi-sprout</v-icon>
                  {{ $t('colheita.colheita') }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.xs">
          <v-row>
            <v-col cols="6" v-if="showHarvestBtn" class="text-right">
              <v-btn outlined color="success" @click="openModalHarvest">
                <v-icon left>mdi-sprout</v-icon>
                &nbsp;{{ $t('colheita.colheita') }}
              </v-btn>
            </v-col>
            <v-col class="text-right">
              <v-menu v-if="showMenu" bottom left>
                <template #activator="{ on }">
                  <v-btn dark icon v-on="on">
                    <v-icon color="blue-grey darken-2">
                      mdi-dots-horizontal
                    </v-icon>
                  </v-btn>
                </template>
                <v-list v-if="semeadura && currentFarm">
                  <v-list-item
                    v-if="hasPermission($p.planting.edit) && enabledFarm"
                    link
                  >
                    <v-list-item-title @click="openPlantingForm(semeadura)">
                      <v-icon>mdi-pencil</v-icon> &nbsp; {{ $t('editar') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="programa && !semeadura.colheita"
                    @click="unlinkPrograma"
                  >
                    <v-list-item-title
                      ><v-icon>mdi-close-circle</v-icon> &nbsp;
                      {{ $t('programa.desassociar') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="hasPermission($p.harvest.create) && enabledFarm"
                    @click="openModalHarvest"
                  >
                    <v-list-item-title
                      ><v-icon>mdi-sprout</v-icon> &nbsp;
                      {{ $t('colheita.colheita') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <modal-semeadura
        v-if="openPlantingModal"
        width="500"
        :dialog="openPlantingModal"
        :semeadura="semeadura"
        @close="closePlantingForm"
      />
      <modal-colheita
        v-if="isOpenModalHarvest && semeadura"
        :dialog="isOpenModalHarvest"
        @close="colheita"
        :semeadura="semeadura"
        :minimum-harvest-date="minimumHarvestDate"
      />
    </div>
  </v-col>
</template>
