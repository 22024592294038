var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{attrs:{"cols":"12"}},[(_vm.hasSowingReadyToHarvest)?_c('message-ready-to-harvest'):_vm._e(),_c('div',[_c('crop-tabs',{staticClass:"pt-3"}),_c('v-row',{staticClass:"mt-3 mb-5",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{staticClass:"d-none d-sm-flex"},[_c('span',{staticClass:"title-cultivo"},[_vm._v(" "+_vm._s(_vm.$t('semeadura.semeadura'))+" ")])]),_c('v-col',{staticClass:"pr-7",attrs:{"md":"auto"}},[(_vm.currentFarmId)?_c('button-season-report',{attrs:{"farm-id":_vm.currentFarmId,"start-period":_vm.currentSeasonCrop.start_period,"end-period":_vm.currentSeasonCrop.end_period,"is-soybean":_vm.isSoybean,"is-wheat":_vm.isWheat}}):_vm._e()],1),_c('v-col',{attrs:{"md":"2","lg":"2"}},[_c('selector-seasons-crops',{on:{"changed":_vm.prepareSeasonCropParameters}})],1)],1),(_vm.loading)?_c('loader'):_vm._e(),(!_vm.loading)?_c('div',[(!_vm.enabledFarm)?_c('div',[(!_vm.hasSowing)?_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('farm-disabled')],1)],1):_vm._e()],1):_vm._e(),(_vm.enabledFarm)?_c('div',[(_vm.isOutSowingTime)?_c('v-row',[_c('v-col',[_c('v-alert',{staticClass:"mt-4",attrs:{"icon":"mdi-shield-lock-outline","prominent":"","text":"","type":"info"}},[_vm._v(" "+_vm._s(_vm.$t('semeadura.farm_sowing_time'))+" ")])],1)],1):_vm._e(),(!_vm.isActiveSeasonCrop && !_vm.isOutSowingTime)?_c('v-row',[_c('v-col',[_c('v-alert',{staticClass:"mt-4",attrs:{"icon":"mdi-shield-lock-outline","prominent":"","text":"","type":"info"}},[_vm._v(" "+_vm._s(_vm.$t('semeadura.inactive_sowing_period'))+" ")])],1)],1):_vm._e(),(_vm.withoutBagPrice)?_c('without-bag-price',{attrs:{"current-farm":_vm.currentFarm}}):_vm._e(),(_vm.isEnableToSowing)?_c('div',[(
              _vm.isMobile &&
              _vm.hasSowing &&
              _vm.hasPermission(_vm.$p.planting.create) &&
              _vm.isActiveSeasonCrop
            )?_c('v-row',{staticClass:"flex-container"},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"xs":"4","sm":"3"}},[_c('v-btn',{staticClass:"rounded-lg",staticStyle:{"font-size":"10px"},attrs:{"dark":"","color":"#8bc34a","height":"30px","width":"130px"},on:{"click":function($event){return _vm.openPlantingForm(null)}}},[_vm._v(" "+_vm._s(_vm.$t('sowing.new'))+" ")])],1)],1):_vm._e(),(!_vm.withoutBagPrice && !_vm.hasSowing && _vm.isActiveSeasonCrop)?_c('without-sowing',{attrs:{"dialog":_vm.openPlantingModal,"semeadura":_vm.plantingSelected},on:{"close":_vm.closePlantingForm,"reload-sowing-list":_vm.shootSowingList}}):_vm._e(),(!_vm.withoutBagPrice && _vm.hasSowing)?_c('v-row',{attrs:{"no-gutters":""}},[(
                !_vm.isMobile &&
                _vm.hasPermission(_vm.$p.planting.create) &&
                _vm.isActiveSeasonCrop
              )?_c('v-col',{staticClass:"add-card-create-planting",attrs:{"justify":"center","align":"center","col":"12","md":"3","sm":"4","lg":"3"}},[_c('v-card',{attrs:{"height":"200px","width":"200px"},on:{"click":function($event){return _vm.openPlantingForm(null)}}},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{staticClass:"pt-6",attrs:{"md":"6","sm":"4"}},[_c('v-img',{attrs:{"src":"img/icon_registerSowing.png"}})],1),_c('v-col',{attrs:{"md":"12","sm":"4"}},[_c('div',[_c('v-card-text',{staticClass:"add-title-main"},[_vm._v(" "+_vm._s(_vm.$t('register.sowing_register'))+" ")])],1)])],1)],1)],1):_vm._e(),_vm._l((_vm.availablePlantings),function(planting){return _c('v-col',{key:planting.id,staticClass:"pt-2",attrs:{"cols":"12","md":"3","sm":"4","xs":"12","justify":"center","align":"center"}},[_c('v-item',[_c('list-item',{attrs:{"semeadura":planting},on:{"edit-planting":_vm.openPlantingForm,"actionFinalized":_vm.shootSowingList}})],1)],1)})],2):_vm._e(),(_vm.openPlantingModal)?_c('modal-semeadura',{attrs:{"dialog":_vm.openPlantingModal,"semeadura":_vm.plantingSelected},on:{"close":_vm.closePlantingForm,"reload-sowing-list":_vm.shootSowingList}}):_vm._e()],1):_vm._e()],1):_vm._e()]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }