<template>
  <v-tooltip v-bind="$attrs">
    <template #activator="{ on, attrs }">
      <div v-on="on" v-bind="attrs"><slot></slot></div>
    </template>
    <span>{{ $t(text) }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
}
</script>
