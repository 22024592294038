<script>
import moment from 'moment'

export default {
  props: {
    execution: { type: Object, required: true },
  },

  computed: {
    daysLater() {
      const idealDate = moment(this.execution.data_ideal)
      const realizedDate = this.execution.data_realizada
        ? moment(this.execution.data_realizada)
        : null

      return realizedDate
        ? realizedDate.diff(idealDate, 'days')
        : moment().diff(idealDate, 'days')
    },
  },
}
</script>
<template>
  <v-row justify="center" v-if="daysLater > 0">
    <v-col cols="12" class="text-center py-0">
      <div class="yellow--text text--darken-2 caption">
        {{ $t('dias_de_atraso', [daysLater]) }}
      </div>
    </v-col>
  </v-row>
</template>
