<template>
  <v-row justify="center">
    <v-dialog
      :value="dialog"
      width="600"
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <template #activator="{ on }">
        <div v-on="on" style="height: 100% !important; width: 100% !important">
          <slot></slot>
        </div>
      </template>
      <v-card>
        <v-toolbar color="blue-grey darken-3">
          <v-toolbar-title style="color: white"
            >{{ $t('toolbar.title_modal_crop_bag_price') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pt-5">
            <v-col
              v-for="bag_price in bagPrices"
              :key="bag_price.crop_id"
              cols="12"
            >
              <bag-price v-model="bag_price.price" :crop-id="bag_price.crop_id">
              </bag-price>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="submit">
            {{ $t('salvar') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import BagPrice from '@/components/Inputs/BagPrice/BagPrice.vue'

export default {
  components: { BagPrice },
  name: 'WithoutBagPrice',
  data() {
    return {
      defaultBagPrices: [
        {
          crop_id: null,
          price: null,
        },
      ],
    }
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },

    currentFarm: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters('seasonsCrops', ['enabledCrops']),

    bagPrices() {
      return this.currentFarm?.bag_price?.length > 0
        ? this.currentFarm.bag_price
        : this.setDefaultBagPrices()
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },

    setDefaultBagPrices() {
      this.defaultBagPrices = this.enabledCrops.map((crop) => {
        return {
          crop_id: crop.id,
          price: 0,
        }
      })

      return this.defaultBagPrices
    },

    notValidPrice() {
      const validate = this.bagPrices.map((item) => {
        return item.price <= 0
      })

      validate.forEach((item) => {
        if (item) {
          this.$root.$emit('notify', 'error', this.$t('required_price'))
        }
      })

      return validate.includes(true)
    },

    async submit() {
      if (this.notValidPrice()) {
        return
      }

      try {
        this.$api.fazendas.bagPrices(this.currentFarm.id, {
          bag_price: this.bagPrices,
        })

        this.close()

        this.$root.$emit(
          'notify',
          'success',
          this.$t('crop.save.price.success')
        )
        this.$router.go()
      } catch (err) {
        this.$root.$emit('notify', 'error', this.$t('crop.save.price.error'))
        throw err
      }
    },
  },
}
</script>

<style></style>
