var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showMenu && _vm.enabledFarm)?_c('v-menu',{attrs:{"bottom":"","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"qa-menu__open-btn",attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1843299661)},[_c('v-list',[(_vm.hasPermission(_vm.$p.defensive.edit) && _vm.enabledFarm)?_c('v-list-item',{on:{"click":_vm.editDefensive}},[_c('v-list-item-title',{staticClass:"qa-menu__edit-btn"},[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v("   "+_vm._s(_vm.$t('editar'))+" ")],1)],1):_vm._e(),(_vm.isDoing)?_c('export-prescription-btn',{attrs:{"is-menu-item":""}}):_vm._e(),(
        _vm.hasPermission(_vm.$p.defensive.edit) && _vm.isLast && _vm.isDone && _vm.enabledFarm
      )?_c('v-list-item',{staticClass:"qa-menu__undo-btn",on:{"click":_vm.undoDefensive}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-undo")]),_vm._v("   "+_vm._s(_vm.$t('desfazer'))+" ")],1)],1):_vm._e(),(
        _vm.hasPermission(_vm.$p.defensive.delete) &&
        _vm.isLast &&
        (_vm.isTodo || _vm.isDoing) &&
        _vm.enabledFarm
      )?_c('v-divider'):_vm._e(),(
        _vm.hasPermission(_vm.$p.defensive.delete) &&
        _vm.isLast &&
        (_vm.isTodo || _vm.isDoing) &&
        _vm.enabledFarm
      )?_c('v-list-item',{staticClass:"qa-menu__delete-btn",on:{"click":_vm.deleteDefensive}},[_c('v-list-item-title',{staticClass:"red--text"},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")]),_vm._v("   "+_vm._s(_vm.$t('excluir')))],1)],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }