<script>
import moment from 'moment'
import { currentLocale } from '@/language'
import { convertToTargetUnit } from '@/components/Form/converter'
import ConverterSlider from '@/components/Form/ConverterSlider'

export default {
  components: {
    ConverterSlider,
  },

  props: {
    irrigacao: {
      type: Object,
      default: () => ({
        id: null,
        fim: moment().format('YYYY-MM-DD HH:mm'),
        inicio: moment().format('YYYY-MM-DD HH:mm'),
        lamina_mm: 0,
      }),
    },
  },

  data() {
    return {
      dialog: false,
      saving: false,
      menuInicioData: false,
      menuFimData: false,
      menuInicioTime: false,
      menuFimTime: false,
      initialDate: false,
      finalDate: false,
      initialTime: false,
      finalTime: false,
      irrigation: {},
    }
  },

  watch: {
    finalTime() {
      if (this.finalTime < this.initialTime) {
        this.finalTime = this.initialTime
      }
    },

    initialTime() {
      if (this.initialTime > moment().format('HH:mm')) {
        this.initialTime = moment().format('HH:mm')
      }
    },
  },

  created() {
    this.irrigation = { ...this.irrigacao }
    this.initialDate = moment(this.irrigation.inicio).format('YYYY-MM-DD')
    this.finalDate = moment(this.irrigation.fim).format('YYYY-MM-DD')
    this.initialTime = moment(this.irrigation.inicio).format('HH:mm')
    this.finalTime = moment(this.irrigation.fim).format('HH:mm')
  },

  computed: {
    getLocale() {
      return currentLocale()
    },

    lamina() {
      return convertToTargetUnit(this.irrigation.lamina_mm, 'rainfall')
    },

    mobile: function () {
      const { name } = this.$vuetify.breakpoint
      return name === 'sm' || name === 'xs'
    },

    formatedInitialDate() {
      return this.initialDate.formatDate()
        ? this.initialDate.formatDate()
        : null
    },

    formatedFinalDate() {
      return this.finalDate.formatDate() ? this.finalDate.formatDate() : null
    },
  },

  methods: {
    async submit() {
      this.irrigation.inicio = this.initialDate + ' ' + this.initialTime
      this.irrigation.fim = this.finalDate + ' ' + this.finalTime

      if (this.irrigation.fim < this.irrigation.inicio) {
        this.$root.$emit(
          'notify',
          'error',
          this.$t('irrigacao.valida_data_time')
        )
        return
      }

      if (this.irrigation.lamina_mm <= 0) {
        this.$root.$emit('notify', 'error', this.$t('irrigacao.valida_lamina'))
        return
      }

      this.saving = true

      try {
        this.$emit('saveIrrigacao', this.irrigation)
        this.close()
        this.saving = false
      } catch (error) {
        this.$root.$emit('notify', 'error', this.$t('irrigacao.salvar_erro'))
      }
    },
    close() {
      this.dialog = false
      this.$emit('fecharMenu')
    },

    minus(value) {
      this.irrigation.lamina_mm = this.irrigation.lamina_mm - value
    },

    plus(value) {
      this.irrigation.lamina_mm = this.irrigation.lamina_mm + value
    },
  },
}
</script>

<template>
  <v-dialog v-model="dialog" persistent @keydown.esc="close">
    <template #activator="{ on }">
      <slot name="activator" :on="on" />
    </template>
    <v-card>
      <v-card-title
        class="headline blue-grey darken-2 white--text"
        primary-title
      >
        {{ $t('irrigacao.irrigacao') }}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <form @keydown.enter="submit">
          <v-row>
            <v-col>
              <v-menu
                ref="menuInicioData"
                v-model="menuInicioData"
                :close-on-content-click="false"
                :return-value.sync="initialDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatedInitialDate"
                    :label="$t('irrigacao.data_inicio')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :locale="getLocale"
                  v-model="initialDate"
                  :max="finalDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuInicioData = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuInicioData.save(initialDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                ref="menuInicioTime"
                v-model="menuInicioTime"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="initialTime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="initialTime"
                    :label="$t('irrigacao.hora_inicio')"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  format="24hr"
                  v-if="menuInicioTime"
                  v-model="initialTime"
                  :max="moment().format('HH:mm')"
                  full-width
                  @click:minute="$refs.menuInicioTime.save(initialTime)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                ref="menuFimData"
                v-model="menuFimData"
                :close-on-content-click="false"
                :return-value.sync="finalDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatedFinalDate"
                    :label="$t('irrigacao.data_fim')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="finalDate"
                  :locale="getLocale"
                  :max="moment().format('YYYY-MM-DD')"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuFimData = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuFimData.save(finalDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                ref="menuFimTime"
                v-model="menuFimTime"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="finalTime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="finalTime"
                    :label="$t('irrigacao.hora_fim')"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  format="24hr"
                  v-if="menuFimTime"
                  v-model="finalTime"
                  :min="initialTime"
                  :max="moment().format('HH:mm')"
                  full-width
                  @click:minute="$refs.menuFimTime.save(finalTime)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="mt-5" v-if="mobile">
            <div class="ml-4 mt-1">{{ $t('irrigacao.precipitacao') }}</div>
          </v-row>
          <v-row class="mt-5">
            <div v-if="!mobile" class="ml-4 mt-1" :max-height="300">
              {{ $t('irrigacao.precipitacao') }}
            </div>
            <converter-slider
              v-model="irrigation.lamina_mm"
              @minus="minus"
              @plus="plus"
            ></converter-slider>
          </v-row>
          <v-row>
            <v-col cols="5">
              <h1>
                {{ lamina }} {{ $unitMeasures['rainfall'][$currentLanguage()] }}
              </h1>
            </v-col>
          </v-row>
        </form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="grey darken-1" @click="close">{{
            $t('cancelar')
          }}</v-btn>
          <v-btn color="primary" @click="submit">{{ $t('salvar') }}</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style scoped="true">
v-text-field {
  height: 20px;
}
</style>
