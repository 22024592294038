<script>
import { mapGetters, mapActions } from 'vuex'
import PermissionMixin from '@/components/Permission/PermissionMixin'
import DRadioField from '@/components/Form/DRadioField'

export default {
  components: { DRadioField },
  mixins: [PermissionMixin],
  data() {
    return {
      isSaving: false,
      planningProgram: null,
      newProgramName: null,
      white_mold_enabled: false,
    }
  },

  async created() {
    await this.loadProgramasPlanejados()
  },

  computed: {
    ...mapGetters('execucoes', ['programasPlanejados']),
    ...mapGetters('crops', ['isSoybean']),
    ...mapGetters('farms', ['enabledFarm']),
  },

  methods: {
    ...mapActions('execucoes', [
      'createPrograma',
      'importPrograma',
      'loadProgramasPlanejados',
    ]),

    async importFromPlanejamento() {
      try {
        this.isSaving = true
        await this.importPrograma(this.planningProgram)
        this.isSaving = false
      } catch (e) {
        this.isSaving = false
      }
    },

    async create() {
      try {
        this.isSaving = true
        const newProgram = {
          nome: this.newProgramName,
          white_mold_enabled: this.white_mold_enabled,
        }
        await this.createPrograma(newProgram)
        this.isSaving = false
      } catch (e) {
        this.isSaving = false
      }
    },
  },
}
</script>
<template>
  <v-container v-if="hasPermission($p.program.create) && enabledFarm">
    <v-row justify="center" align="center">
      <v-col cols="12" lg="4" md="4" sm="12">
        <v-card class="grow" elevation="0">
          <v-card-title>{{ $t('execucao.programa.importar') }}</v-card-title>
          <v-card-text>
            <v-select
              v-model="planningProgram"
              :label="$t('program.planned_programs')"
              :items="programasPlanejados"
              item-value="id"
              item-text="nome"
              hide-details
              single-line
              return-object
              :disabled="isSaving"
            ></v-select>
          </v-card-text>
          <v-card-actions class="mt-2">
            <v-btn
              color="primary"
              block
              large
              :disabled="isSaving"
              :loading="isSaving"
              @click="importFromPlanejamento"
            >
              {{ $t('importar') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" lg="2" md="2" sm="12">
        <div class="text-center headline">{{ $t('or') }}</div>
      </v-col>

      <v-col cols="12" lg="4" md="4" sm="12">
        <v-card elevation="0">
          <v-card-title>{{ $t('execucao.programa.novo') }}</v-card-title>
          <v-card-text class="pb-0">
            <v-text-field
              v-model="newProgramName"
              :label="$t('programa.nome_programa')"
              :disabled="isSaving"
            >
            </v-text-field>
          </v-card-text>
          <v-card-text
            class="py-0"
            v-if="$toggle('white_mold_enabled') && isSoybean"
          >
            <span v-html="$t('programa.white_mold_control_title') + '?'"></span>
            <v-chip
              class="d-inline-flex"
              style="text-transform: uppercase; margin-left: 10px"
              small
              color="primary"
              >{{ $t('test') }}
            </v-chip>
            <d-radio-field
              class="mt-0"
              v-model="white_mold_enabled"
              :disabled="isSaving"
              row
            ></d-radio-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              block
              large
              :disabled="!newProgramName || isSaving"
              :loading="isSaving"
              @click="create"
            >
              {{ $t('criar') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
