<script>
import PermissionMixin from '@/components/Permission/PermissionMixin'
import ExportPrescriptionBtn from '@/components/Buttons/Integrations/ExportPrescriptionBtn'
import { mapGetters } from 'vuex'

export default {
  mixins: [PermissionMixin],

  components: {
    ExportPrescriptionBtn,
  },

  props: {
    isLast: { type: Boolean, required: true },
    disabled: { type: Boolean, required: true },
    type: { type: String, required: true },
  },
  types: {
    done: 'done',
    todo: 'todo',
    doing: 'doing',
  },
  computed: {
    ...mapGetters('farms', ['enabledFarm']),

    showMenu() {
      return (
        this.hasPermission(this.$p.defensive.edit) ||
        this.hasPermission(this.$p.defensive.delete)
      )
    },
    isDone() {
      return this.type === this.$options.types.done
    },
    isDoing() {
      return this.type === this.$options.types.doing
    },
    isTodo() {
      return this.type === this.$options.types.todo
    },
  },
  methods: {
    editDefensive() {
      this.$emit('edit-defensive')
    },
    undoDefensive() {
      this.$emit('undo-defensive')
    },
    deleteDefensive() {
      this.$emit('delete-defensive')
    },
  },
}
</script>

<template>
  <v-menu v-if="showMenu && enabledFarm" bottom :disabled="disabled">
    <template #activator="{ on }">
      <v-btn class="qa-menu__open-btn" icon v-on="on">
        <v-icon color="white">mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-if="hasPermission($p.defensive.edit) && enabledFarm"
        @click="editDefensive"
      >
        <v-list-item-title class="qa-menu__edit-btn"
          ><v-icon>mdi-pencil</v-icon> &nbsp; {{ $t('editar') }}
        </v-list-item-title>
      </v-list-item>

      <export-prescription-btn
        v-if="isDoing"
        is-menu-item
      ></export-prescription-btn>

      <v-list-item
        class="qa-menu__undo-btn"
        v-if="
          hasPermission($p.defensive.edit) && isLast && isDone && enabledFarm
        "
        @click="undoDefensive"
      >
        <v-list-item-title
          ><v-icon>mdi-undo</v-icon> &nbsp; {{ $t('desfazer') }}
        </v-list-item-title>
      </v-list-item>

      <v-divider
        v-if="
          hasPermission($p.defensive.delete) &&
          isLast &&
          (isTodo || isDoing) &&
          enabledFarm
        "
      ></v-divider>

      <v-list-item
        class="qa-menu__delete-btn"
        v-if="
          hasPermission($p.defensive.delete) &&
          isLast &&
          (isTodo || isDoing) &&
          enabledFarm
        "
        @click="deleteDefensive"
      >
        <v-list-item-title class="red--text"
          ><v-icon color="red">mdi-delete</v-icon> &nbsp;
          {{ $t('excluir') }}</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>
