<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { currentLocale } from '@/language'

export default {
  props: {
    execucao: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      applied_at: null,
      saving: false,
      menu: false,
    }
  },

  computed: {
    ...mapGetters('execucoes', ['semeadura', 'execucoes']),
    ...mapGetters('user', ['currentUser']),

    getLocale() {
      return currentLocale()
    },
    maxDate() {
      return this.currentUser?.is_qa_mode_enabled ||
        this.$toggle('DEBUG_MODE_ENABLE')
        ? null
        : moment().format('YYYY-MM-DD')
    },
    minDate() {
      const appliedSprayings = this.execucoes.filter((execucao) =>
        Boolean(execucao.data_realizada)
      )

      const lastAppliedSprayingDate =
        appliedSprayings[appliedSprayings.length - 1]?.data_realizada

      const emergeDateLimit = moment(this.semeadura.data_emergencia)
        .add(1, 'days')
        .format('YYYY-MM-DD')

      if (lastAppliedSprayingDate) {
        if (moment(lastAppliedSprayingDate).isBefore(emergeDateLimit)) {
          return emergeDateLimit
        }

        return moment(lastAppliedSprayingDate)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }

      return emergeDateLimit
    },
  },

  methods: {
    ...mapActions('execucoes', ['applySpray']),

    close() {
      this.dialog = false
    },

    async save() {
      if (!this.applied_at) {
        this.$root.$emit('notify', 'error', 'Selecione uma data para aplicar')
        return
      }

      this.saving = true

      try {
        await this.applySpray({
          programId: this.execucao.programa_id,
          ordinal: this.execucao.aplicacao,
          applied_at: this.applied_at,
        })
        this.close()
        this.saving = false
      } catch (error) {
        const message =
          error?.response?.data?.message || this.$t('execucao.salvar.erro_400')
        this.$root.$emit('notify', 'error', message)
      }
    },

    clean() {
      this.applied_at = null
    },
  },
}
</script>

<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="applied_at"
    transition="scale-transition"
    min-width="290px"
  >
    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>

    <v-date-picker
      v-model="applied_at"
      show-current
      no-title
      scrollable
      :locale="getLocale"
      :max="maxDate"
      :min="minDate"
      :disabled="saving"
    >
      <v-btn text color="red" @click="clean"> {{ $t('limpar') }} </v-btn>
      <v-btn text color="primary" @click="menu = false" :disabled="saving">{{
        $t('cancelar')
      }}</v-btn>
      <v-btn
        color="primary"
        @click="save"
        :loading="saving"
        :disabled="!applied_at"
        >{{ $t('salvar') }}</v-btn
      >
    </v-date-picker>
  </v-menu>
</template>
