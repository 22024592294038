export default {
  soybean: {
    progressSteps: {
      vegetative: ['VE', 'V0', 'V1', 'V2', 'V3', 'V4', 'V5', 'V6', 'V7-V9'],
      reproductiveInitial: ['R1', 'R2', 'R3', 'R4'],
      reproductiveFinal: ['R5.1', 'R5.2', 'R5.3', 'R5.4', 'R5.5'],
      maturation: ['R6', 'R7', 'R8', 'R9'],
    },
    stages: [
      { value: 'VE', label: 'VE' },
      { value: 'V0', label: 'V0' },
      { value: 'V1', label: 'V1' },
      { value: 'V2', label: 'V2' },
      { value: 'V3', label: 'V3' },
      { value: 'V4', label: 'V4' },
      { value: 'V5', label: 'V5' },
      { value: 'V6', label: 'V6' },
      { value: 'V7-V9', label: 'V7-V9' },
      { value: 'R1', label: 'R1' },
      { value: 'R2', label: 'R2' },
      { value: 'R3', label: 'R3' },
      { value: 'R4', label: 'R4' },
      { value: 'R5.1', label: 'R5.1' },
      { value: 'R5.2', label: 'R5.2' },
      { value: 'R5.3', label: 'R5.3' },
      { value: 'R5.4', label: 'R5.4' },
      { value: 'R5.5', label: 'R5.5' },
      { value: 'R6', label: 'R6' },
      { value: 'R7', label: 'R7' },
      { value: 'R8', label: 'R8' },
      { value: 'R9', label: 'R9' },
    ],
  },
  wheat: {
    progressSteps: {
      vegetative: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      reproductiveInitial: ['10.1', '10.2', '10.3', '10.4', '10.5'],
      reproductiveFinal: ['10.5.1', '10.5.2', '10.5.3', '10.5.4'],
      maturation: ['11.1', '11.2', '11.3', '11.4'],
    },
    stages: [
      { value: '0', label: '0 - Germinação' },
      { value: '1', label: '1 - Primeiras folhas' },
      { value: '2', label: '2 - Início perfilhamento' },
      { value: '3', label: '3 - Afilhos formados' },
      { value: '4', label: '4 - Crescimento de bainhas' },
      { value: '5', label: '5 - Pseudocaule desenvolvido' },
      { value: '6', label: '6 - 1º Nó formado' },
      { value: '7', label: '7 - 2º Nó formado' },
      { value: '8', label: '8 - Folha bandeira visível' },
      { value: '9', label: '9 - Lígula da bandeira visível' },
      { value: '10', label: '10 - Emborrachamento' },
      { value: '10.1', label: '10.1 - 1ª Espiga visível' },
      { value: '10.2', label: '10.2 - 25% do espigamento' },
      { value: '10.3', label: '10.3 - 50% do espigamento' },
      { value: '10.4', label: '10.4 - 75% do espigamento' },
      { value: '10.5', label: '10.5 - 100% de espigamento' },
      { value: '10.5.1', label: '10.5.1 - Início florescimento' },
      { value: '10.5.2', label: '10.5.2 - Flor no topo espiga' },
      { value: '10.5.3', label: '10.5.3 - Flor na base espiga' },
      { value: '10.5.4', label: '10.5.4 - Final florescimento' },
      { value: '11.1', label: '11.1 - Grãos leitosos' },
      { value: '11.2', label: '11.2 - Grãos em massa' },
      { value: '11.3', label: '11.3 - Grãos duros' },
      { value: '11.4', label: '11.4 - Maturação' },
    ],
  },
}
