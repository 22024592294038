<script>
import { mapGetters, mapActions } from 'vuex'
import { convertToTargetUnit } from '@/components/Form/converter'

export default {
  async created() {
    await this.loadWeather(this.currentFarmId)
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('execucoes', ['meteorologia']),
  },

  methods: {
    ...mapActions('execucoes', ['loadWeather']),

    diaSemana(i) {
      const dia = this.moment(this.meteorologia.prec_mm_dia[i - 1][0])
        .locale(this.$root.$i18n.locale)
        .format('ddd')

      const hoje = this.moment().locale(this.$root.$i18n.locale).format('ddd')

      let label = this.$t('dias_semana.hoje')
      label = label[0].toUpperCase() + label.slice(1)

      if (dia === hoje) return label
      else return dia
    },

    diaCondicao(i) {
      const mm = this.meteorologia.prec_mm_dia[i - 1][1]

      if (mm < 3) return 'mdi-weather-sunny'
      else if (mm > 3 && mm < 5) return 'mdi-weather-cloudy'
      else return 'mdi-weather-pouring'
    },

    diaTempMax(i) {
      return convertToTargetUnit(
        this.meteorologia.temp_max_min_dia[i - 1][0],
        'temperature'
      )
    },

    diaTempMin(i) {
      return convertToTargetUnit(
        this.meteorologia.temp_max_min_dia[i - 1][1],
        'temperature'
      )
    },
  },
}
</script>

<template>
  <v-row
    no-gutters
    justify="center"
    class="pa-4 pt-0"
    v-if="meteorologia && meteorologia.prec_mm_dia"
  >
    <v-col v-for="i in meteorologia.prec_mm_dia.length" :key="i">
      <v-row no-gutters class="text-center caption grow" justify="center">
        {{ diaSemana(i) }}
      </v-row>

      <v-row no-gutters class="grow" justify="center">
        <v-icon>{{ diaCondicao(i) }}</v-icon>
      </v-row>

      <!-- MinTemperatura -->
      <v-row no-gutters class="grow" justify="center">
        <v-col class="text-center caption blue--text text--darken-1"
          >{{ diaTempMin(i) }}
          {{ $unitMeasures['temperature'][$currentLanguage()] }}</v-col
        >
      </v-row>

      <!-- MaxTemperatura -->
      <v-row no-gutters class="grow" justify="center">
        <v-col class="text-center caption orange--text text--darken-1"
          >{{ diaTempMax(i) }}
          {{ $unitMeasures['temperature'][$currentLanguage()] }}</v-col
        >
      </v-row>
    </v-col>
  </v-row>
</template>
