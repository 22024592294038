<script>
import ModalSemeadura from '@/pages/semeaduras/ModalSemeadura.vue'
import ListItem from '@/pages/semeaduras/ListItem.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import CropTabs from '@/components/Crops/CropTabs.vue'
import LayoutMixin from '@/components/LayoutMixin.vue'
import ButtonSeasonReport from '@/components/ButtonSeasonReport.vue'
import SelectorSeasonsCrops from '@/components/SeasonsCrops/SelectorSeasonsCrops.vue'
import WithoutBagPrice from '@/components/Banners/WithoutBagPrice.vue'
import WithoutSowing from '@/components/Banners/WithoutSowing.vue'
import FarmDisabled from '@/components/Banners/FarmDisabled.vue'
import MessageReadyToHarvest from '@/components/MessageReadyToHarvest.vue'
import Loader from '@/components/Loaders/Loader.vue'
import { logEvent, events } from '@/services/analytics'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  mixins: [PermissionMixin, LayoutMixin],

  components: {
    ModalSemeadura,
    ListItem,
    CropTabs,
    ButtonSeasonReport,
    SelectorSeasonsCrops,
    WithoutBagPrice,
    WithoutSowing,
    FarmDisabled,
    MessageReadyToHarvest,
    Loader,
  },

  data() {
    return {
      plantingSelected: null,
      openPlantingModal: false,
      loading: true,
    }
  },

  async mounted() {
    if (
      this.$toggle('tour_enabled') &&
      !localStorage.getItem('tour-selector')
    ) {
      this.$tours['tourUnifiedFarm']?.start()
    }

    logEvent(events.sowings.visitedPage)
  },

  computed: {
    ...mapGetters('farms', ['currentFarm', 'currentFarmId', 'enabledFarm']),
    ...mapGetters('user', ['currentUser']),
    ...mapGetters('semeaduras', ['plantingsByCrop', 'sowingsReadyToHarvest']),
    ...mapGetters('crops', ['isSoybean', 'isWheat']),
    ...mapGetters('seasonsCrops', ['currentSeasonCrop']),

    isEnableToSowing() {
      if (
        this.currentUser?.is_qa_mode_enabled ||
        this.$toggle('DEBUG_MODE_ENABLE')
      ) {
        return true
      }

      return moment(
        this.currentSeasonCrop?.start_period,
        'YYYY-MM-DD'
      ).isSameOrBefore(moment())
    },

    isOutSowingTime() {
      return this.currentFarm && !this.isEnableToSowing
    },

    availablePlantings() {
      return this.isEnableToSowing ? this.plantingsByCrop : []
    },

    isActiveSeasonCrop() {
      return this.currentSeasonCrop.season_active
    },

    hasSowing() {
      return this.plantingsByCrop.length > 0
    },

    withoutBagPrice() {
      return !this.currentFarm.bag_price.length
    },

    hasSowingReadyToHarvest() {
      return this.sowingsReadyToHarvest.length > 1
    },
  },

  methods: {
    ...mapActions('semeaduras', ['loadSowingsFilter']),

    async shootSowingList() {
      try {
        this.loading = true
        await this.loadSowingsFilter()
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },

    openPlantingForm(planting) {
      this.plantingSelected = planting
      this.openPlantingModal = true
    },

    closePlantingForm() {
      this.plantingSelected = null
      this.openPlantingModal = false
    },

    async prepareSeasonCropParameters() {
      try {
        this.loading = true
        await this.loadSowingsFilter()
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
  },
}
</script>

<template>
  <v-col cols="12">
    <message-ready-to-harvest
      v-if="hasSowingReadyToHarvest"
    ></message-ready-to-harvest>

    <div>
      <crop-tabs class="pt-3" />
      <v-row no-gutters justify="center" class="mt-3 mb-5">
        <v-col class="d-none d-sm-flex">
          <span class="title-cultivo">
            {{ $t('semeadura.semeadura') }}
          </span>
        </v-col>
        <v-col md="auto" class="pr-7">
          <button-season-report
            v-if="currentFarmId"
            :farm-id="currentFarmId"
            :start-period="currentSeasonCrop.start_period"
            :end-period="currentSeasonCrop.end_period"
            :is-soybean="isSoybean"
            :is-wheat="isWheat"
          ></button-season-report>
        </v-col>
        <v-col md="2" lg="2">
          <selector-seasons-crops
            @changed="prepareSeasonCropParameters"
          ></selector-seasons-crops>
        </v-col>
      </v-row>

      <loader v-if="loading"></loader>

      <div v-if="!loading">
        <div v-if="!enabledFarm">
          <v-row no-gutters justify="center" v-if="!hasSowing">
            <v-col cols="12">
              <farm-disabled></farm-disabled>
            </v-col>
          </v-row>
        </div>

        <div v-if="enabledFarm">
          <v-row v-if="isOutSowingTime">
            <v-col>
              <v-alert
                class="mt-4"
                icon="mdi-shield-lock-outline"
                prominent
                text
                type="info"
              >
                {{ $t('semeadura.farm_sowing_time') }}
              </v-alert>
            </v-col>
          </v-row>

          <v-row v-if="!isActiveSeasonCrop && !isOutSowingTime">
            <v-col>
              <v-alert
                class="mt-4"
                icon="mdi-shield-lock-outline"
                prominent
                text
                type="info"
              >
                {{ $t('semeadura.inactive_sowing_period') }}
              </v-alert>
            </v-col>
          </v-row>

          <without-bag-price
            v-if="withoutBagPrice"
            :current-farm="currentFarm"
          ></without-bag-price>

          <div v-if="isEnableToSowing">
            <v-row
              v-if="
                isMobile &&
                hasSowing &&
                hasPermission($p.planting.create) &&
                isActiveSeasonCrop
              "
              class="flex-container"
            >
              <v-col xs="4" sm="3" class="d-flex justify-end">
                <v-btn
                  dark
                  color="#8bc34a"
                  height="30px"
                  width="130px"
                  style="font-size: 10px"
                  class="rounded-lg"
                  @click="openPlantingForm(null)"
                >
                  {{ $t('sowing.new') }}
                </v-btn>
              </v-col>
            </v-row>

            <without-sowing
              v-if="!withoutBagPrice && !hasSowing && isActiveSeasonCrop"
              :dialog="openPlantingModal"
              :semeadura="plantingSelected"
              @close="closePlantingForm"
              @reload-sowing-list="shootSowingList"
            />

            <v-row v-if="!withoutBagPrice && hasSowing" no-gutters>
              <v-col
                v-if="
                  !isMobile &&
                  hasPermission($p.planting.create) &&
                  isActiveSeasonCrop
                "
                justify="center"
                align="center"
                col="12"
                md="3"
                sm="4"
                lg="3"
                class="add-card-create-planting"
              >
                <v-card
                  @click="openPlantingForm(null)"
                  height="200px"
                  width="200px"
                >
                  <v-row justify="center" no-gutters>
                    <v-col md="6" sm="4" class="pt-6">
                      <v-img src="img/icon_registerSowing.png" />
                    </v-col>
                    <v-col md="12" sm="4">
                      <div>
                        <v-card-text class="add-title-main">
                          {{ $t('register.sowing_register') }}
                        </v-card-text>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

              <v-col
                v-for="planting in availablePlantings"
                :key="planting.id"
                cols="12"
                md="3"
                sm="4"
                xs="12"
                justify="center"
                align="center"
                class="pt-2"
              >
                <v-item>
                  <list-item
                    :semeadura="planting"
                    @edit-planting="openPlantingForm"
                    @actionFinalized="shootSowingList"
                  />
                </v-item>
              </v-col>
            </v-row>
            <modal-semeadura
              v-if="openPlantingModal"
              :dialog="openPlantingModal"
              :semeadura="plantingSelected"
              @close="closePlantingForm"
              @reload-sowing-list="shootSowingList"
            />
          </div>
        </div>
      </div>
    </div>
  </v-col>
</template>

<style lang="scss" scoped>
::v-deep .add-title-main {
  margin-bottom: 11px;
}
.title-cultivo {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #071505;
}

.btn-top {
  height: 100% !important;
  width: 100% !important;
}

.div-btn-bottom {
  display: inline;
}

.btn-bottom {
  height: 100% !important;
  width: 100% !important;

  color: #1e1;
}

.card-text-position {
  position: absolute;
  bottom: 0;
}

.add-card-create-planting {
  margin-bottom: 20px;

  @media (min-width: 1264px) {
    padding-right: 45px !important;
    padding-left: 45px !important;
  }
}

@media (min-width: 1904px) {
  .add-card-create-planting {
    padding-right: 118px !important;
    padding-left: 118px !important;
  }
}
</style>
